const convertTime12to24 = (time12h) => {
	const [time] = time12h.split(' ');

	let [hours] = time.split(':');
	if (hours === '12') {
		hours = '00';
	}

	const last2 = time12h.slice(-2);

	if (last2 === 'PM') {
		hours = parseInt(hours, 10) + 12;
	}
	return hours;
};

document.querySelectorAll('.ct__footer--hours').forEach(function (selector) {
	const openHour = document.querySelector('.hours-status').getAttribute('data-open');
	const closeHour = document.querySelector('.hours-status').getAttribute('data-closed');

	const open = convertTime12to24(openHour);
	const close = convertTime12to24(closeHour);

	const now = new Date();
	const currentTime = now.getHours();

	const width = window.innerWidth;
	const width2 = selector.clientWidth;

	const openStringDesktop = selector.querySelector('.open__time__footer.desktop');
	const openStringMobile = selector.querySelector('.open__time__footer.mobile');
	const closeStringHeader = selector.querySelector('.close__time__footer.header');

	if (openStringDesktop) {
		openStringDesktop.style.display = 'none';
	}

	if (openStringMobile) {
		openStringMobile.style.display = 'none';
	}

	if (closeStringHeader) {
		closeStringHeader.style.display = 'none';
	}

	if (width < 480 || width2 < 480) {
		if (openStringMobile && closeStringHeader) {
			if (currentTime >= open && currentTime <= close) {
				openStringMobile.style.display = 'inline';
				closeStringHeader.style.display = 'none';
			} else {
				openStringMobile.style.display = 'none';
				closeStringHeader.style.display = 'inline';
			}
		}
	} else if (openStringDesktop && closeStringHeader) {
		if (currentTime >= open && currentTime <= close) {
			openStringDesktop.style.display = 'inline';
			closeStringHeader.style.display = 'none';
		} else {
			openStringDesktop.style.display = 'none';
			closeStringHeader.style.display = 'inline';
		}
	}
});
